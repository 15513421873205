<template>
  <div></div>
</template>

<script>
export default {
  mounted(){
    this.$router.push({ path: '/' })
  }
}
</script>
