<template>
  <router-view />
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      users: []
    };
  },
  mounted() {
    window.addEventListener(
      "contextmenu",
      function(e) {
        e.preventDefault();
      },
      false
    );
  }
};
</script>


<style lang="scss">
body,
html {
  padding: 0px;
  margin: 0px;
  overscroll-behavior: contain;
}
html {
  position: relative;
  height: 100%;
}
body {
  position: relative;
  height: 100%;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #eee;
}

::-webkit-scrollbar-thumb {
  background: #000;
}

::selection {
  background: #ccc;
}

::-moz-selection {
  background: #ccc;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
  height: 100%;
}
* {
  font-size: 16px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

@media only print {
    body { visibility: hidden; display: none }
}

</style>
