<template>
  <div v-if="isAdmin" id="logout" @click="logout">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16 16"
    >
      <rect width="16" height="16" id="icon-bound" fill="none" />
      <path
        d="M14,14l0,-12l-6,0l0,-2l8,0l0,16l-8,0l0,-2l6,0Zm-9.002,-0.998l-4.998,-5.002l5,-5l1.416,1.416l-2.588,2.584l8.172,0l0,2l-8.172,0l2.586,2.586l-1.416,1.416Z"
      />
    </svg>
  </div>
  <div v-if="!inits" id="mainWrap">
    <div v-if="!isAdmin" id="LoginWrap">
      <div id="loginForm">
        <div id="caption">
          Please enter your password<br />to access the admin panel.
        </div>
        <div id="frmFlex">
          <div id="inputWrap">
            <div id="sh" @click="togglePwd">
              <svg
                v-if="pwdType == 'password'"
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="eye-slash"
                class="svg-inline--fa fa-eye-slash fa-w-20"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
              >
                <path
                  fill="currentColor"
                  d="M634 471L36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z"
                ></path>
              </svg>
              <svg
                v-else
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="eye"
                class="svg-inline--fa fa-eye fa-w-18"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
              >
                <path
                  fill="currentColor"
                  d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"
                ></path>
              </svg>
            </div>
            <input
              ref="pwdInput"
              v-model="pwdField"
              :type="pwdType"
              autofocus
            />
          </div>
          <button @click="login">
            <span v-if="loginInit == false">Login</span>
            <easy-spinner v-else class="spinner" size="25" color="#fff" />
          </button>
        </div>
      </div>
    </div>
    <div v-else id="split">
      <div id="left">
        <div id="addPwd">
          <input
            type="text"
            v-model="pwdEntered"
            placeholder="Enter the password"
          />
          <select name="Menu" id="smenu" v-model="menuSelected">
            <option value="0">Select the catalogue</option>
            <option value="1">Menu</option>
            <option value="2">Memberships</option>
          </select>
          <button
            v-bind:class="{ addDisabled: !addCheck }"
            @click="addNewPassword"
          >
            Add
          </button>
        </div>
        <div id="addMenu">
          <input
            id="fic1"
            @click="fck(1)"
            type="readonly"
            v-model="menu1File"
          />
          <input
            hidden
            ref="fix1"
            type="file"
            v-on:change="
              setPhotoFile($event.target.name, $event.target.files[0], 1)
            "
            name="menu1[]"
            accept="application/pdf"
          />
          <button
            @click="uploadMenu(1)"
            v-bind:class="{ disabled: menus[1] == undefined }"
          >
            <div
              ref="progress1"
              style="
                z-index: 2;
                display: block;
                background-color: #0b0;
                width: 0%;
                height: 100%;
                position: absolute;
                top: 0px;
                left: 0px;
              "
            ></div>
            <div style="position: relative; z-index: 200; display: block">
              {{ up1Text }}
              <easy-spinner
                v-if="menuSending[1] == true"
                class="spinner"
                size="20"
                color="#fff"
              />
            </div>
          </button>
          <div class="separator"></div>
          <input
            id="fic2"
            @click="fck(2)"
            type="readonly"
            v-model="menu2File"
          />
          <input
            hidden
            ref="fix2"
            type="file"
            v-on:change="
              setPhotoFile($event.target.name, $event.target.files[0], 2)
            "
            name="menu2[]"
            accept="application/pdf"
          />
          <button
            v-bind:class="{ disabled: menus[2] == undefined }"
            @click="uploadMenu(2)"
          >
            <div
              ref="progress2"
              style="
                z-index: 2;
                display: block;
                background-color: #0b0;
                width: 0%;
                height: 100%;
                position: absolute;
                top: 0px;
                left: 0px;
              "
            ></div>
            <div style="position: relative; z-index: 200; display: block">
              {{ up2Text }}
              <easy-spinner
                v-if="menuSending[2] == true"
                class="spinner"
                size="20"
                color="#fff"
              />
            </div>
          </button>
        </div>
      </div>
      <div id="right">
        <div class="pwdListElement pleHeader">
          <div class="plePwd"><strong>Password</strong></div>
          <div class="pleMenu"><strong>Menu</strong></div>
          <div class="pleNol"><strong>NoL</strong></div>
          <div class="pleValid"><strong>Days</strong></div>
        </div>
        <easy-spinner
          class="lpwd"
          v-if="pwdLoading == true"
          size="40"
          color="#ddd"
        />
        <transition-group name="list" tag="p">
          <div
            class="list-item pwdListElement"
            v-for="item in pwdList"
            :key="item.pwd"
          >
            <div
              @click="copyToClipboard($event.target.textContent)"
              class="plePwd"
            >
              {{ item.pwd }}
            </div>
            <div class="pleMenu">{{ menuLocations[item.mno] }}</div>
            <div class="pleNol">{{ item.nol }}</div>
            <div class="pleValid">{{ daysDiff(item.valid) }}</div>
            <div class="pleBtn" :id="item.pwd" v-on:click="check($event)">
              x
            </div>
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      inits: true,
      isAdmin: false,
      loginInit: false,
      pwdField: "",
      pwdavis: true,
      visible: false,
      pwdType: "password",
      pwdEntered: "",
      menuSelected: 0,
      menus: [undefined, undefined, undefined],
      menuSending: [false, false, false],
      menuConverting: [false, false, false],
      checkPointer: null,
      pwdList: [],
      pwdLoading: true,
      menuLocations: ["", "Menu", "Memberships"],
      lgt: false
    };
  },
  computed: {
    addCheck: function() {
      return this.pwdEntered != "" && this.menuSelected > 0;
    },
    up1Text: function() {
      return this.menuSending[1]
        ? this.menuConverting[1]
          ? "Converting"
          : "Uploading"
        : "Upload";
    },
    up2Text: function() {
      return this.menuSending[2]
        ? this.menuConverting[2]
          ? "Converting"
          : "Uploading"
        : "Upload";
    },
    menu1File: function() {
      return this.menus[1] == undefined
        ? "Choose Menu PDF"
        : this.$refs.fix1.files[0].name;
    },
    menu2File: function() {
      return this.menus[2] == undefined
        ? "Choose Memberships PDF"
        : this.$refs.fix2.files[0].name;
    }
  },
  async mounted() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" }
    };
    const response = await fetch(
      "https://www.ahmmenu.com/checka.php",
      requestOptions
    );
    const data = await response.json().catch(e => {
      this.$toast.error(e);
    });
    if (data.status == "ok") {
      this.loginInit = false;
      this.inits = false;
      this.$toast.show("Welcome Administrator.");
      this.isAdmin = true;
      this.getPwds();
    } else {
      this.inits = false;
      this.$nextTick(() => {
        this.$refs.pwdInput.addEventListener("keyup", this.armInput);
      });
    }
    this.inits = false;
    document.querySelector("#waitLoading").remove();
    document.addEventListener("click", this.closeCheck);
  },
  beforeUnmount: function() {
    document.addEventListener("click", this.closeCheck);
  },
  methods: {
    async logout() {
      if (!this.lgt) {
        this.lgt = true;
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" }
        };
        const response = await fetch(
          "https://www.ahmmenu.com/logout.php",
          requestOptions
        );
        const data = await response.json().catch(e => {
          this.$toast.error(e);
          this.lgt = false;
        });
        if (data.status == "ok") {
          window.location.reload();
        }
        this.lgt = false;
      }
    },
    async login() {
      this.loginInit = true;
      this.pwdType = "password";

      var req = JSON.stringify({
        pwd: this.pwdField
      });
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: req
      };
      const response = await fetch(
        "https://www.ahmmenu.com/checka.php",
        requestOptions
      );
      const data = await response.json().catch(e => {
        this.$toast.error(e);
      });
      console.log(data);
      if (data.status == "ok") {
        this.$toast.show("Welcome Administrator.");
        this.loginInit = false;
        this.isAdmin = true;
        this.getPwds();
      } else if (data.status == "not") {
        this.loginInit = false;
        this.$toast.error("Sorry, wrong password...");
      } else {
        this.loginInit = false;
        this.$toast.error(
          "There was an error, please try again in few seconds."
        );
      }
    },
    togglePwd() {
      this.pwdType = this.pwdType == "password" ? "text" : "password";
    },
    fck(f) {
      switch (f) {
        case 1:
          this.$refs.fix1.click();
          break;
        case 2:
          this.$refs.fix2.click();
          break;
      }
    },
    daysDiff(valid) {
      const date1 = new Date(parseInt(valid));
      const date2 = new Date(Date.now());
      const diffTime = Math.abs(date2 - date1);
      return Math.ceil(diffTime / 86400000) - 1;
    },

    async getPwds() {
      const response = await fetch("https://www.ahmmenu.com/getkhmkhm.php");
      const data = await response.json();
      if (data["status"] == "ok") {
        this.pwdList = data["payload"];
      } else if (data["status"] == "empty") {
        this.$toast.warning("Password list is empty.");
      } else {
        this.$toast.error("There was an error loading passwords.");
      }
      this.pwdLoading = false;
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
      this.$toast.info("Copied to clipboard: " + text);
    },

    calcValid() {
      var today = new Date(Date.now());
      var date = new Date(today.toLocaleDateString("en-US"));
      return date.getTime();
    },
    armInput(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.login();
      }
    },

    async addNewPassword() {
      if (this.addCheck) {
        var req = JSON.stringify({
          pwd: this.pwdEntered,
          mno: parseInt(this.menuSelected),
          valid: this.calcValid()
        });
        console.log(req);
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: req
        };
        const response = await fetch(
          "https://www.ahmmenu.com/addpwd.php",
          requestOptions
        );
        const data = await response.json().catch(e => {
          this.$toast.error(e);
        });
        console.log(data);
        if (data.status == "ok") {
          this.$toast.success("New password added: " + this.pwdEntered);
          this.copyToClipboard(this.pwdEntered);
          this.getPwds();
          this.pwdEntered = "";
          this.menuSelected = 0;
        } else {
          this.$toast.error(
            "There was an error, please try again in few seconds."
          );
        }
      } else {
        this.$toast.error("You must enter a password and choose the menu.");
      }
    },

    async deletePwd(pwd) {
      var req = JSON.stringify({
        pwd: pwd
      });
      console.log(req);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: req
      };
      const response = await fetch(
        "https://www.ahmmenu.com/delpwd.php",
        requestOptions
      );
      const data = await response.json().catch(e => {
        this.$toast.error(e);
      });
      console.log(data);
      if (data.status == "ok") {
        this.$toast.success("Password deleted: " + pwd);
      } else {
        this.$toast.error(
          "There was an error, please try again in few seconds."
        );
      }
      this.getPwds();
    },

    closeCheck() {
      if (this.checkPointer != null) {
        this.checkPointer.textContent = "x";
        this.checkPointer = null;
      }
    },

    check(event) {
      if (event.target.textContent == "Sure?") {
        this.checkPointer = null;
        event.target.textContent = "Deleting";
        this.deletePwd(event.target.id);
      } else if (event.target.textContent != "Deleting") {
        this.closeCheck();
        this.checkPointer = event.target;
        this.checkPointer.textContent = "Sure?";
      }
      event.stopPropagation();
    },

    async setPhotoFile(fieldName, file, menuNo) {
      this.menus[menuNo] = file;
    },

    async uploadMenu(menuNo) {
      if (this.menus[menuNo] == undefined) {
        this.$toast.warning(
          "You must choose Menu PDF file for location " +
            this.menuLocations[menuNo]
        );
        return;
      }
      if (this.menuSending[menuNo] == true) {
        this.$toast.warning(
          "Already uploading Menu " +
            this.menuLocations[menuNo] +
            ". Pelase wait..."
        );
        return;
      }
      this.menuSending[menuNo] = true;
      let formData = new FormData();
      formData.append("file", this.menus[menuNo]);
      formData.append("menuNo", menuNo);

      let config = {
        //withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress: function(progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this.onProgress(percentCompleted, menuNo);
          return percentCompleted;
        }.bind(this)
      };
      this.axios
        .post("https://www.ahmmenu.com/uploadmenu.php", formData, config)
        .then(x => {
          const data = x.data;
          if (data.status == "ok") {
            this.$toast.success(
              this.menuLocations[menuNo] + " Menu upload completed."
            );
            this.menus[menuNo] = undefined;
            this.menuSending[menuNo] = false;
          } else {
            this.$toast.error("Upload error! Please try again.");
            this.menuSending[menuNo] = false;
          }
          this.menuSending[menuNo] = false;
          this.menus[menuNo] = undefined;
          this.onProgress(0, menuNo);
        })
        .catch(error => {
          this.$toast.error(error);
          this.menuSending[menuNo] = false;
          this.onProgress(0, menuNo);
        });
    },
    onProgress(percent, menuNo) {
      percent == 100
        ? (this.menuConverting[menuNo] = true)
        : (this.menuConverting[menuNo] = false);

      menuNo == 1
        ? (this.$refs.progress1.style.width = percent + "%")
        : (this.$refs.progress2.style.width = percent + "%");
    }
  }
};
</script>

<style scoped>
#mainWrap {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.spinner {
  position: absolute;
  display: inline-block;
  margin-left: 10px;
}

input {
  background-color: white;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 15px 20px;
  border: 1px solid #ddd;
  margin: 2px;
  width: 243px;
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

input[type="file"] {
  width: 338px;
  height: 25px;
  padding: 12px;
  cursor: pointer;
}

select {
  padding: 16px 20px;
  border: none;
  border-radius: 4px;
  background-color: #f1f1f1;
}

button {
  background-color: #489e4b;
  border: none;
  color: white;
  padding: 16px 32px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
  position: relative;
}

#split {
  display: flex;
}

#left {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: #fafafa;
}

#addPwd {
  display: block;
  width: 100%;
  position: relative;
  margin-top: 50px;
}
#addMenu {
  margin-top: 50px;
  display: block;
  width: 100%;
}

.separator {
  width: 100%;
  height: 10px;
}

#addMenu button {
  width: 190px;
}

#right {
  width: 100%;
  height: 100vh;
  padding: 49px 10px;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
}

.pwdListElement {
  position: relative;
  width: 80%;
  display: flex;
  flex-direction: row;
  padding: 10px;
  background-color: #eee;
  margin: 5px 30px;
}
.pleHeader {
  background-color: #ddd;
}

.plePwd {
  flex-grow: 1;
  text-align: left;
  padding-left: 15px;
  cursor: grab;
}

.pleMenu {
  width: 110px;
  border-left: 1px solid #000;
}
.pleNol {
  width: 90px;
  border-left: 1px solid #000;
}
.pleValid {
  width: 90px;
  border-left: 1px solid #000;
}
.pleBtn {
  background-color: rgb(233, 192, 192);
  padding: 0px 10px;
  height: 25px;
  color: #fff;
  transition: 0.3s;
  position: absolute;
  right: -13px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc(50% - 13px);
  font-size: 13px;
  border-radius: 30px;
}

.pleBtn:hover {
  cursor: pointer;
  background-color: rgb(200, 21, 21);
}

.disabled {
  background-color: #ddd;
  color: #555;
}
.disabled:hover {
  background-color: #ddd;
  color: #555;
  cursor: no-drop;
}
.expired {
  background-color: rgb(255, 217, 217);
}
.addDisabled {
  background-color: #ddd !important;
  color: #555;
}
.list-item {
}
.list-move {
  transition: transform 0.5s ease;
}
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.lpwd {
  margin-top: 50px;
}

#fic1,
#fic2 {
  width: 338px;
  height: 25px;
  padding: 12px;
  cursor: pointer;
}
#loginForm {
  font-family: "Merriweather", serif;
  background-color: #fff;
  width: 570px;
  max-width: 90%;
  height: 300px;
  transition: 0.4s;
  transition-timing-function: cubic-bezier(-1, 0.5, 0.1, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-60%) translateX(-50%);
  box-shadow: 0px 0px 120px -30px RGBA(0, 0, 0, 0.2);
}

#loginForm .spinner {
  position: relative;
}

@media only screen and (max-width: 600px) {
  #loginForm {
    justify-content: flex-end;
  }
}

.visible {
  opacity: 1 !important;
}

.visible #loginForm {
  transform: scale(1) translateY(0vh);
}

#frmFlex {
  display: flex;
  justify-content: center;
}

#formClose {
  font-family: Arial, Arial, Helvetica, sans-serif;
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 25px;
  font-weight: 100;
  color: #dedede;
}
#formClose:hover {
  color: #444;
  cursor: pointer;
}
#frmFlex button {
  transform: scale(1) !important;
  background-color: rgb(58, 61, 63);
  color: #eee;
  margin: 0vh;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 100px;
  height: 50px;
}

@media only screen and (max-width: 600px) {
  #frmFlex button {
    margin: 5%;
    width: 90%;
    height: 92px;
  }
}

#frmFlex button:hover {
  background-color: rgb(22, 22, 22);
  color: #fff;
}
#sh {
  display: flex;
  width: 27px;
  height: 35px;
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
}

#sh svg {
  opacity: 0.1;
  width: 100%;
}

#sh svg:hover {
  opacity: 0.3;
  cursor: pointer;
}

#caption {
  font-size: 20px;
  line-height: 1.5;
  margin: 0px 0px 40px 0px;
}

@media only screen and (max-width: 600px) {
  #caption {
    font-size: 16px;
    line-height: 1.5;
    margin: 0px 0px 40px 0px;
  }
}

#inputWrap {
  position: relative;
  height: 100%;
}

#loginForm input[type="password"] {
  font-size: 31px !important;
  padding: 0px 60px 0px 20px;
  margin: 0px;
  box-sizing: border-box;
  width: 300px;
  height: 100%;
  font-size: 16px;
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
#loginForm input[type="text"] {
  font-size: 20px;
  padding: 0px 60px 0px 20px;
  margin: 0px;
  box-sizing: border-box;
  width: 300px;
  height: 100%;
  font-size: 16px;
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

@media only screen and (max-width: 600px) {
  #loginForm input[type="password"] {
    font-size: 20px;
    padding: 0px 60px 0px 20px;
    margin: 0px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }
  #loginForm input[type="text"] {
    font-size: 20px;
    padding: 0px 60px 0px 20px;
    margin: 0px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }
}

#logout {
  position: absolute;
  right: 1vw;
  top: 1vw;
  padding: 15px 25px 15px 10px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.2;
  transition: 0.3s;
  z-index: 9999999;
  cursor: pointer;
}

#logout svg {
  fill: #000;
  position: relative;
  display: block;
  height: 20px;
  transition: 0.3s;
}
#logout::before {
  width: 0px;
  content: "Logout";
  color: #fff;
  float: left;
  margin-right: 10px;
  font-size: 16px;
  transition: 0.3s;
  opacity: 0;
}

#logout:hover::before {
  width: 70px;
  content: "Logout";
  color: #fff;
  float: left;
  margin-right: 10px;
  font-size: 16px;
  opacity: 1;
}

#logout:hover {
  background-color: #000;
  opacity: 1;
  prefix: "logout";
}

#logout:hover svg {
  fill: #fff;
}

@media only screen and (max-width: 600px) {
  #mainWrap {
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  #split {
    display: flex;
    flex-direction: column;
  }

  #left {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-color: #fafafa;
    padding-bottom: 40px;
  }

  #right {
    width: 100%;
    height: auto;
    padding: 49px 10px;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
  }

  #fic1,
  #fic2 {
    width: 75vw;
    height: 55px;
    padding: 12px;
    cursor: pointer;
    box-sizing: border-box;
  }

  #addMenu button {
    width: 75vw;
  }
  .pwdListElement {
    position: relative;
    width: 90%;
    display: flex;
    flex-direction: row;
    padding: 10px;
    background-color: #eee;
    margin: 5px 9px;
    cursor: copy;
  }
  .pleNol {
    width: 49px;
    border-left: 1px solid #000;
    font-size: 14px;
  }
  .pleValid {
    width: 60px;
    border-left: 1px solid #000;
    font-size: 14px;
  }
  .pleMenu {
    width: 67px;
    border-left: 1px solid #000;
    font-size: 14px;
  }
  #frmFlex {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
</style>