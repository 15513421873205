<template>
  <div v-if="numberOfpages > 0" id="logout" @click="logout">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16 16"
    >
      <rect width="16" height="16" id="icon-bound" fill="none" />
      <path
        d="M14,14l0,-12l-6,0l0,-2l8,0l0,16l-8,0l0,-2l6,0Zm-9.002,-0.998l-4.998,-5.002l5,-5l1.416,1.416l-2.588,2.584l8.172,0l0,2l-8.172,0l2.586,2.586l-1.416,1.416Z"
      />
    </svg>
  </div>
  <div id="mainWrap" v-bind:class="{ mainWrapr: numberOfpages > 0 }">
    <div
      v-bind:class="{ test: visible, reading: numberOfpages > 0 }"
      id="menuWrap"
    >
      <img
        v-bind:class="{ initsImg: inits }"
        @click="showLogin"
        src="https://www.ahmmenu.com/firstpage.php"
        draggable="false"
      />
      <img
        v-for="index in numberOfpages"
        :key="index"
        v-bind:class="{ initsImg: inits }"
        :src="'https://www.ahmmenu.com/download.php?p=' + index"
        draggable="false"
      />
      <div
        v-if="numberOfpages > 0"
        id="overImages"
        @click="toggleFullScreen"
      ></div>
    </div>
    <button
      v-if="numberOfpages == 0"
      v-bind:class="{ initsButton: inits }"
      id="openBtn"
      @click="showLogin"
    >
      Open
    </button>
    <div id="wlink">
      <a
        v-bind:class="{ initsFooter: inits }"
        href="https://www.ahmedicine.com/"
        target="_blank"
        >Visit our website - www.ahmedicine.com</a
      >
    </div>
    <div
      v-bind:class="{ visible: visible }"
      v-if="loginForm"
      id="overlayWrap"
      @click="hideLogin"
    >
      <div id="loginForm" @click="stopProp">
        <div id="formClose" @click="hideLogin">x</div>
        <div id="caption">
          Please enter your password<br />to access the Menu.
        </div>
        <div id="frmFlex">
          <div id="inputWrap">
            <div id="sh" @click="togglePwd">
              <svg
                v-if="pwdType == 'password'"
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="eye-slash"
                class="svg-inline--fa fa-eye-slash fa-w-20"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
              >
                <path
                  fill="currentColor"
                  d="M634 471L36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z"
                ></path>
              </svg>
              <svg
                v-else
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="eye"
                class="svg-inline--fa fa-eye fa-w-18"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
              >
                <path
                  fill="currentColor"
                  d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"
                ></path>
              </svg>
            </div>
            <input
              ref="pwdInput"
              v-model="pwdField"
              :type="pwdType"
              autofocus
            />
          </div>
          <button @click="login">
            <span v-if="loginInit == false">Login</span>
            <easy-spinner v-else class="spinner" size="25" color="#fff" />
          </button>
        </div>
      </div>
    </div>
    <div v-if="loginInit && protectorActive" id="protector"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      inits: true,
      loginForm: false,
      visible: false,
      loginInit: false,
      pwdField: "",
      pwdavis: true,
      pwdType: "password",
      protectorActive: false,
      numberOfpages: 0,
      lgt: false
    };
  },
  async mounted() {
    window.addEventListener("load", async () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" }
      };
      const response = await fetch(
        "https://www.ahmmenu.com/check.php",
        requestOptions
      );
      const data = await response.json().catch(e => {
        this.$toast.error(e);
      });
      if (data.status == "ok") {
        this.numberOfpages = parseInt(data.pages) - 1;
        this.$toast.show("Welcome!");
      }
      this.inits = false;
      document.querySelector("#waitLoading").remove();
    });
  },
  methods: {
    async logout() {
      if (!this.lgt) {
        this.lgt = true;
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" }
        };
        const response = await fetch(
          "https://www.ahmmenu.com/logout.php",
          requestOptions
        );
        const data = await response.json().catch(e => {
          this.$toast.error(e);
          this.lgt = false;
        });
        if (data.status == "ok") {
          window.location.reload();
        }
        this.lgt = false;
      }
    },
    toggleFullScreen() {
      if (
        (document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)
      ) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    async login() {
      this.loginInit = true;
      this.pwdType = "password";

      var req = JSON.stringify({
        pwd: this.pwdField
      });
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: req
      };
      const response = await fetch(
        "https://www.ahmmenu.com/check.php",
        requestOptions
      );
      const data = await response.json().catch(e => {
        this.$toast.error(e);
        this.loginInit = false;
      });
      if (data.status == "ok") {
        this.numberOfpages = parseInt(data.pages) - 1;
        this.$toast.show("Welcome!");
        this.hideLogin();
      } else if (data.status == "not") {
        this.$toast.error("Sorry, wrong password...");
      } else {
        this.$toast.error(
          "There was an error, please try again in few seconds."
        );
      }
      this.loginInit = false;
    },
    togglePwd() {
      this.pwdType = this.pwdType == "password" ? "text" : "password";
    },
    hideLogin() {
      this.loginInit = false;
      this.visible = false;
      this.pwdType = "password";
      this.$refs.pwdInput.removeEventListener("keyup", this.armInput);
      setTimeout(() => {
        this.loginForm = false;
      }, 300);
    },
    showLogin() {
      this.loginForm = true;
      setTimeout(() => {
        this.visible = true;
        this.$refs.pwdInput.addEventListener("keyup", this.armInput);
        this.$refs.pwdInput.focus();
        this.$refs.pwdInput.select();
      }, 20);
    },
    armInput(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.login();
      }
    },
    stopProp(event) {
      event.stopPropagation();
    }
  }
};
</script>
<style scoped>
#overImages {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  display: block;
}
#protector {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100vh;
  width: 100vw;
  display: block;
  cursor: wait;
}

#sh {
  display: flex;
  width: 27px;
  height: 35px;
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
}

#sh svg {
  opacity: 0.1;
  width: 100%;
}

#sh svg:hover {
  opacity: 0.3;
  cursor: pointer;
}

.spinner {
  position: relative;
}

#wlink {
  position: relative;
  z-index: 9999;
  width: 100vw;
  padding: 5vh 0vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 5;
}

#wlink a {
  opacity: 1;
  font-family: "Merriweather", serif;
  font-weight: 100;
  font-size: 1.6vh;
  text-decoration: none;
  color: #bbb;
  transition: all 0.3s, opacity 1s;
  transition-timing-function: ease-out;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
}

#wlink a:hover {
  color: #666;
}

#mainWrap {
  position: relative;
  width: 100%;
  height: 100vh;
  display: block;
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  #mainWrap {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}

.mainWrapr {
  overflow-y: auto !important;
  height: 100% !important;
}

#mainWrap button {
  position: relative;
  z-index: 888;
  font-family: "Merriweather", serif;
  font-weight: 500;
  background-color: transparent;
  border: 1px solid rgb(87, 87, 87);
  color: rgb(41, 41, 41);
  padding: 2vh 5vh;
  transition: all 0.3s, opacity 1s, transform 0.3s;
  transition-timing-function: cubic-bezier(0.5, -0.75, 0.7, 2);
  font-size: 2vh;
  transform: scale(1) translateZ(0) translateY(0);
  backface-visibility: hidden;
  margin-top: 4vh;
  opacity: 1;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  #mainWrap button {
    margin: 4vh 26vw;
    flex-grow: 1;
  }
}

#mainWrap button:hover {
  border: 1px solid rgb(46, 46, 46);
  transform: scale(1.1) translateZ(0);
  backface-visibility: hidden;
  background-color: rgb(22, 22, 22);
  color: #eee;
}

.dajmuga {
  border: 1px solid rgb(46, 46, 46) !important;
  transform: scale(1.1) translateZ(0) !important;
  backface-visibility: hidden !important;
  background-color: rgb(22, 22, 22) !important;
  color: #eee !important;
}

#frmFlex button {
  transform: scale(1) !important;
  background-color: rgb(58, 61, 63);
  color: #eee;
  margin: 0vh;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 100px;
  height: 50px;
}

@media only screen and (max-width: 600px) {
  #frmFlex button {
    margin: 5%;
    width: 90%;
    height: 92px;
  }
}

#frmFlex button:hover {
  background-color: rgb(22, 22, 22);
  color: #fff;
}

#formClose {
  font-family: Arial, Arial, Helvetica, sans-serif;
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 25px;
  font-weight: 100;
  color: #dedede;
}
#formClose:hover {
  color: #444;
  cursor: pointer;
}
#menuWrap {
  position: relative;
  z-index: 999;
  width: 100%;
  transform: translateX(-50%);
  margin-left: 50%;
  transition: 0.3s;
  transition-timing-function: cubic-bezier(0, 0.5, 0.1, 1);
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8vh;
}

.reading {
  height: auto !important;
  transition: 0.6s !important;
  transition-timing-function: cubic-bezier(0, 0.2, 0.022, 1) !important;
  justify-content: start !important;
}

@media only screen and (max-width: 600px) {
  #menuWrap {
    margin-top: 0vh;
    flex-grow: 1;
    justify-content: flex-end;
    padding-top: 5vh;
    box-sizing: border-box;
  }
  .reading {
    margin-top: 2vh !important;
  }
}

#menuWrap img {
  filter: blur(0px);
  display: block;
  max-height: 100%;
  max-width: 100%;
  box-shadow: 0px 0px 90px 0px RGBA(0, 0, 0, 0.2);
  cursor: pointer;
  opacity: 1;
  transform: scale(1);
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0, 0.2, 0.1, 1);
}

.test {
  filter: blur(10px) !important;
  margin-top: -10vh !important;
  height: 1100px !important;
  transition: 0.6s !important;
  transition-timing-function: cubic-bezier(0, 0.2, 0.022, 1) !important;
  justify-content: start !important;
}

@media only screen and (max-width: 600px) {
  .test {
    margin-top: 8vh !important;
    height: 70% !important;
    transition: 0.6s !important;
    transition-timing-function: cubic-bezier(0, 0.2, 0.022, 1) !important;
    justify-content: start !important;
  }
}

#overlayWrap {
  z-index: 9999;
  left: 0px;
  top: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: RGBA(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.4s;
  transition-timing-function: cubic-bezier(0.25, 0.75, 0.5, 1.25);
}

#caption {
  font-size: 20px;
  line-height: 1.5;
  margin: 0px 0px 40px 0px;
}

@media only screen and (max-width: 600px) {
  #caption {
    font-size: 16px;
    line-height: 1.5;
    margin: 0px 0px 40px 0px;
  }
}

#inputWrap {
  position: relative;
  height: 100%;
}

input[type="password"] {
  font-size: 31px !important;
  padding: 0px 60px 0px 20px;
  margin: 0px;
  box-sizing: border-box;
  width: 300px;
  height: 100%;
  font-size: 16px;
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
input[type="text"] {
  font-size: 20px;
  padding: 0px 60px 0px 20px;
  margin: 0px;
  box-sizing: border-box;
  width: 300px;
  height: 100%;
  font-size: 16px;
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

@media only screen and (max-width: 600px) {
  input[type="password"] {
    font-size: 20px;
    padding: 0px 60px 0px 20px;
    margin: 0px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }
  input[type="text"] {
    font-size: 20px;
    padding: 0px 60px 0px 20px;
    margin: 0px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }
}

#loginForm {
  font-family: "Merriweather", serif;
  background-color: #fff;
  width: 570px;
  max-width: 90%;
  height: 300px;
  transform: scale(0.5) translateY(2vh);
  transition: 0.4s;
  transition-timing-function: cubic-bezier(-1, 0.5, 0.1, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  #loginForm {
    justify-content: flex-end;
  }
}

.visible {
  opacity: 1 !important;
}

.visible #loginForm {
  transform: scale(1) translateY(0vh);
}

#frmFlex {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  #frmFlex {
    display: flex;
    justify-content: stretch;
    flex-direction: column;
  }
}

.initsImg {
  opacity: 0 !important;
  transform: scale(0) !important;
  box-shadow: 0px 0px 90px 0px RGBA(0, 0, 0, 0) !important;
}

.initsButton {
  opacity: 0 !important;
  transform: scale(1) translateZ(0) translateY(-50px) !important;
}

.initsFooter {
  opacity: 0 !important;
}

#logout {
  position: absolute;
  right: 1vw;
  top: 1vw;
  padding: 15px 25px 15px 10px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.2;
  transition: 0.3s;
  z-index: 9999999;
  cursor: pointer;
}

#logout svg {
  fill: #000;
  position: relative;
  display: block;
  height: 20px;
  transition: 0.3s;
}
#logout::before {
  width: 0px;
  content: "Logout";
  color: #fff;
  float: left;
  margin-right: 10px;
  font-size: 16px;
  transition: 0.3s;
  opacity: 0;
}

#logout:hover::before {
  width: 70px;
  content: "Logout";
  color: #fff;
  float: left;
  margin-right: 10px;
  font-size: 16px;
  opacity: 1;
}

#logout:hover {
  background-color: #000;
  opacity: 1;
  prefix: "logout";
}

#logout:hover svg {
  fill: #fff;
}
</style>